import React from 'react'

const PageNotFound = (props) => {
        return (
            <div className="container">
                <p>404</p>
                <p>Page not found </p>
            </div>
        )
}

export default PageNotFound